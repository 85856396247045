<template>
  <div class="row justify-content-center">
    <div class="col">
      <Form action="" class="form" data-vv-scope="form-1" @submit="sendInfo('form-1')">
        <h2 class="titlePage_subtitle titlePage">Account management</h2>
        <div class="form__row">
          <div class="form__col_2">

            <label class="form__label">
              <span class="form__labelTitle">First Name</span>
              <Field v-model="user.user.name" :rules="{required: true}"
                     class="form__input"
                     name="first name"
                     placeholder="First Name"
                     type="text"
              />
              <ErrorMessage class="form__error" name="first name"/>
            </label>
          </div>
          <div class="form__col_2">
            <label class="form__label">
              <span class="form__labelTitle">Last Name</span>
              <Field v-model="user.user.last_name" :rules="{required: true}"
                     class="form__input"
                     name="last name"
                     placeholder="Last Name"
                     type="text"
              />
              <ErrorMessage class="form__error" name="last name"/>
            </label>
          </div>
        </div>

        <p class="text">What sizes do you prefer to enter?</p>

        <div class="form__row">
          <div class="form__col_2">
            <label class="form__label form__label_row">
              <input v-model="size_entering_type" class="form__radio"
                     hidden
                     name="size_entering_type"
                     type="radio"
                     value="Opening"
                     @change="showInfoConfirmationModal = true"
              >
              <span class="form__radio_icon"></span>
              <span class="form__labelTitle">Layout sizes</span>
            </label>
          </div>
          <div class="form__col_2">
            <label class="form__label form__label_row">
              <input v-model="size_entering_type" class="form__radio"
                     hidden
                     name="size_entering_type"
                     type="radio"
                     value="Manufacture"
                     @change="showInfoConfirmationModal = true"
              >
              <span class="form__radio_icon"></span>
              <span class="form__labelTitle">Make sizes</span>
            </label>
          </div>
        </div>

        <p class="text">What size do you enter first?</p>

        <div class="form__row">
          <div class="form__col_2">
            <label class="form__label form__label_row">
              <input v-model="prefer_entry" class="form__radio"
                     hidden
                     name="prefer"
                     type="radio"
                     value="width"
                     @change="showInfoConfirmationModal = true"
              >
              <span class="form__radio_icon"></span>
              <span class="form__labelTitle">Width</span>
            </label>
          </div>
          <div class="form__col_2">
            <label class="form__label form__label_row">
              <input v-model="prefer_entry" class="form__radio"
                     hidden
                     name="prefer"
                     type="radio"
                     value="drop"
                     @change="showInfoConfirmationModal = true"
              >
              <span class="form__radio_icon"></span>
              <span class="form__labelTitle">Drop</span>
            </label>
          </div>
        </div>
        <div class="divider"></div>
        <label class="form__label form__label_row">
          <input v-model="skip_packages"
                 class="form__checkbox"
                 hidden="hidden"
                 type="checkbox"
                 @change="checkSkip"
          >
          <span class="form__checkbox_icon"></span>
          <span class="form__labelTitle">Allow adding packages to order</span>
        </label>

        <p>

          <!--          <span class="form__error">{{ errors.first('form-1.prefer') }}</span>-->
        </p>
        <button class="btn_default btn" type="submit">Update</button>
      </Form>

      <Form action="" class="form" data-vv-scope="form-2" @submit="sendPassword('form-2')">
        <h2 class="titlePage_subtitle titlePage">Change password</h2>

        <label class="form__label">
          <span class="form__labelTitle">Current Password</span>
          <Field v-model="password.current_password" :rules="{required: true, min: 4}"
                 autocomplete="off"
                 class="form__input"
                 name="current password"
                 placeholder="Current Password"
                 type="password"
          />
          <ErrorMessage class="form__error" name="current password"/>
        </label>
        <label class="form__label">
          <span class="form__labelTitle">New Password</span>
          <Field id="password" ref="password"
                 v-model="password.password"
                 :rules="{required: true, min: 4}"
                 autocomplete="off"
                 class="form__input"
                 name="password"
                 placeholder="New Password"
                 type="password"
          />
          <ErrorMessage class="form__error" name="new password"/>
        </label>
        <label class="form__label">
          <span class="form__labelTitle">Confirmed Password</span>
          <Field v-model="password.password_confirm" :rules="'required|confirmed:@password'"
                 autocomplete="off"
                 class="form__input"
                 label="password"
                 name="confirm password"
                 placeholder="Confirmed Password"
                 type="password"
          />
          <ErrorMessage class="form__error" name="confirm password"/>
        </label>
        <button class="btn_default btn" type="submit">Change Password</button>
      </Form>
<!--      <div class="divider"></div>-->
<!--      <button class="btn btn_default" @click="logOut">Log out</button>-->
    </div>

    <InfoConfirmationModal v-model:show="showInfoConfirmationModal"
                           message="Changing this setting will update the way you enter sizes immediately, however, all other users will be required to refresh the page in order to this change take place"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import InfoConfirmationModal from '@/components/commons/InfoConfirmationModal.vue'
import {
  isOpeningIsThePreferToEnter,
  isWidthIsTheSizeToEnterFirst
} from '../../helpers/portalCommons.js'
export default {
  name: "Account",
  components: {
    InfoConfirmationModal
  },

  data() {
    return {
      prefer_entry: '',
      size_entering_type: '',
      skip_packages: JSON.parse(localStorage.getItem('skip_packages')),
      showInfoConfirmationModal: false,
    }
  },

  watch: {
    'entry'() {
      this.prefer_entry = this.$store.getters['account/preferEntries'];
    },
    'entry_type'() {
      this.size_entering_type = this.$store.getters['account/sizeEnteringType'];
    }
  },

  methods: {
    ...mapActions(
      {
        logOut: 'auth/logout',
      }
    ),

    sendInfo(scope) {
      this.$store.dispatch('account/updateInfo', {
        name: this.user.user.name,
        last_name: this.user.user.last_name,
        prefer_entry: this.prefer_entry,
        size_entering_type: this.size_entering_type,
        skip_packages: this.skip_packages,
      });
    },

    sendPassword(scope) {
      this.$store.dispatch('account/changePassword', {
        current_password: this.password.current_password,
        password: this.password.password,
        password_confirm: this.password.password_confirm,
      });
    },

    checkSkip() {
      this.$store.commit('auth/skipPackages', this.skip_packages);
    }
  },

  computed: {
    ...mapGetters(
      {
        user: 'account/userInfo',
        errors: 'account/errors',
        password: 'account/passwordInfo',
        skipPackages: 'auth/skipPackages',
        entry: 'account/preferEntries',
        entry_type: 'account/preferEntries'
      }
    ),
  },

  created() {
    this.$store.dispatch('account/getInfo');
    this.prefer_entry = this.$store.getters['account/preferEntries'];
    this.size_entering_type = this.$store.getters['account/sizeEnteringType'];
  },

  mounted() {
    this.prefer_entry = this.$store.getters['account/preferEntries'];
    this.size_entering_type = this.$store.getters['account/sizeEnteringType'];
    console.log(isOpeningIsThePreferToEnter());
    console.log(isWidthIsTheSizeToEnterFirst())
  },

}

</script>

<style scoped>

</style>
