<script setup>
import Modal from '@/components/modals/Modal.vue';

const emits = defineEmits(['update:show', 'continue']);

const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: 'Confirmation'
  },
  message: {
    type: String,
    default: 'Are you sure you want to continue ?'
  }
})

</script>

<template>
  <Modal v-if="show" :handleClose="() =>emits('update:show',false)">
    <template #title-text>
      {{ props.title }}
    </template>

    <template #description>
      <div>
        <div>{{ props.message }}</div>

        <div class="tw-flex tw-gap-4 tw-mt-4">

          <button class="tw-btn-secondary-success" type="button"
                  @click="emits('update:show',false)">
            Ok
          </button>

        </div>
      </div>
    </template>

    <template #footer>
      {{ null }}
    </template>
  </Modal>
</template>
